import React, { FunctionComponent } from 'react'
import PragmaticSolution from '../components/pragmatic-solution';
import PrecisionTeam from '../components/precision-team';
import UniqueCharacteristics from '../components/unique-characteristics';
import DeliveringExcellence from '../components/delivering-excellence';
import AgilityBeyond from '../components/agility-beyond';

const Services:FunctionComponent =()=> {


  return (
    <>
      <PragmaticSolution/>
      <PrecisionTeam/>
      <UniqueCharacteristics/>
      <DeliveringExcellence/>
      <AgilityBeyond/>
    </>
  )
}

export default Services;