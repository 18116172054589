import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper'
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'

const UniqueCharacteristics:FunctionComponent = ()=> {
    return (
        <Section className="bg-bytestrone-blue px-6">
            <div id="unique-characteristics" className="pb-5"></div>
            <div className="flex flex-col w-full h-full p-5 py-10 space-y-5">
                <Text className="text-center text-white lg:text-right whitespace-nowrap" textType="headerL">Unique Characteristics</Text>
            
                <div>
                    <Text textType="contentXXl" className="text-white">
                        Teams built on character than just skills can do wonders.
                    </Text>
                </div>

                <div>
                    <Text textType="contentXXl" className="text-white">
                        We believe these qualities are of paramount importance apart from Engineering
                        Proficiency. We build teams that imbibe these characteristics.
                    </Text>
                </div>

                <div className="flex flex-wrap h-full items-center  justify-left md:pl-11 space-y-5 text-white">
   
                    <div className="lg:w-1/2">
                        <StaticImage alt="Precision-crafted team" placeholder="blurred" quality={100} 
                            src="../images/unique-characteristics.png" formats={["auto", "webp", "avif"]}/>
                    </div>

                    <div className="lg:w-1/2 md:px-10">
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">1</div>
                            <span className="pl-5">We take care of every aspect of Engineering</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">2</div>
                            <span className="pl-5">We practice lean Software Engineering</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">3</div>
                            <span className="pl-5">We embrace fail-fast</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">4</div>
                            <span className="pl-5">We communicate facts – whether pleasant or unpleasant</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">5</div>
                            <span className="pl-5">Accountability is our promise that bonds commitment to results</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">6</div>
                            <span className="pl-5">Engineering Excellence, for us, is not an act but a habit</span>
                        </div>                                                                                                                        
                    </div>

                </div>
            </div>
        </Section>
    )
}

export default UniqueCharacteristics;
