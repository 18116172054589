import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper'
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'

const AgilityBeyond:FunctionComponent = ()=> {
    return (
        <Section className="bg-bytestrone-blue px-6">
            <div id="agility-beyond" className="pb-5"></div>
            <div className="flex flex-col w-full h-full p-5 py-10 space-y-5">
                <Text className="text-center text-white lg:text-right whitespace-nowrap" textType="headerL">Agility Beyond Engineering</Text>
            
                <div>
                    <Text textType="contentXXl" className="text-white">
                        We excel at Engineering. We also understand the big picture. We proactively help
                        customers address every aspect of delivery.
                    </Text>
                </div>

                <div>
                    <Text textType="contentXXl" className="text-white">
                        We are committed to project success by going above and beyond just engineering.
                    </Text>
                </div>

                <div className="flex flex-wrap h-full md:pl-11 space-y-5 text-white">
   
                    <div className="flex w-full justify-center items-center">
                        <div className="hidden md:block">
                            <StaticImage alt="Precision-crafted team" placeholder="blurred" quality={100} 
                                src="../images/agility-beyond-engineering.png" formats={["auto", "webp", "avif"]}/>
                        </div>

                        <div className="md:hidden">
                            <StaticImage alt="Precision-crafted team" placeholder="blurred" quality={100} 
                                src="../images/agility-beyond-engineering-mobile.png" formats={["auto", "webp", "avif"]}/>
                        </div>
                    </div>

                    <div className="md:px-10 sm:px-5">
                        <div className="flex flex-row h-18 py-2">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">1</div>
                            <span className="pl-5">Continuous refinement of estimates &amp; plan to improve predictability of delivery and reduce cone of uncertainty</span>
                        </div>
                        <div className="flex flex-row h-18 py-2">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">2</div>
                            <span className="pl-5">Shift-left approach for functional / non-functional validations</span>
                        </div>
                        <div className="flex flex-row h-18 py-2">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">3</div>
                            <span className="pl-5">Proactive participation for roll-out strategy definition and planning</span>
                        </div>
                        <div className="flex flex-row h-18 py-2">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">4</div>
                            <span className="pl-5">Proactive solution architecture validation for functional &amp; non-functional requirement alignment</span>
                        </div>
                        <div className="flex flex-row h-18 py-2">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">5</div>
                            <span className="pl-5">Providing real-time insight &amp; reporting of product backlog health in ALM</span>
                        </div>
                        <div className="flex flex-row h-18 py-2">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">6</div>
                            <span className="pl-5">Constructive functional &amp; technical grooming to unearth end to end view</span>
                        </div>          
                        <div className="flex flex-row h-18 py-2">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">7</div>
                            <span className="pl-5">Continuous validation of backlog against DoR before sprint commitment</span>
                        </div>   
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-white flex items-center justify-center text-black">8</div>
                            <span className="pl-5">Design considerations for right instrumentation tools</span>
                        </div>                                                                                                                 
                    </div>

                </div>
            </div>
        </Section>
    )
}

export default AgilityBeyond;
