import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper'
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'

const DeliveringExcellence:FunctionComponent = ()=> {
    return (
        <Section className="bg-white px-6">
            <div id="delivering-excellence" className="pb-5"></div>
            <div className="flex flex-col w-full h-full p-5 py-10 space-y-5">
                <Text className="text-center text-bytestrone-blue lg:text-left" textType="headerL">
                    Delivering Engineering Excellence</Text>
            
                <div className="">
                    <Text textType="contentXXl">
                        We pride ourselves in taking care of all aspects of Engineering. Engineering Excellence
                        comes second nature to us as our teams have been continuously sharpening the skills
                        and acquiring new knowledge.
                    </Text>
                </div>

                <div className="">
                    <Text textType="contentXXl">
                        When you choose Bytestrone, none of these are an after-thought.
                    </Text>
                </div>

                <div className="flex flex-wrap h-full items-center justify-left md:pl-11 space-y-5">
   
                    <div className="lg:w-1/2">
                        <StaticImage alt="Precision-crafted team" placeholder="blurred" quality={100} 
                            src="../images/delivering-engineering-excellence.png" formats={["auto", "webp", "avif"]}/>
                    </div>

                    <div className="lg:w-1/2 md:pl-10">
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-bytestrone-blue flex items-center justify-center text-white">1</div>
                            <span className="pl-5">We deliver optimal code quality with continuous validation &amp;
                                rigorous integrated quality gates, enabled through relevant and
                                comprehensive rules &amp; plug-ins.</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-bytestrone-blue flex items-center justify-center text-white">2</div>
                            <span className="pl-5">We rely on extensive instrumentation for continuous debugging,
                                testing &amp; hardening.</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-bytestrone-blue flex items-center justify-center text-white">3</div>
                            <span className="pl-5">Holistic approach to maintainability through adoption of right
                                architecture, design patterns, frameworks, code refactoring &amp;
                                tooling.</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-bytestrone-blue flex items-center justify-center text-white">4</div>
                            <span className="pl-5">Applying the right design patterns and frameworks to achieve
                                Performance, Reliability and Resilience.</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-bytestrone-blue flex items-center justify-center text-white">5</div>
                            <span className="pl-5">Applying best practices &amp; real world experience to avoid common pitfalls.</span>
                        </div>
                        <div className="flex flex-row h-18 py-2 items-center">
                            <div className="flex-none w-10 h-10 font-bold rounded-full bg-bytestrone-blue flex items-center justify-center text-white">6</div>
                            <span className="pl-5">We improve speed and stability of Product Development by adopting/implementing DevOps.</span>
                        </div>                                                                                                                        
                    </div>

                </div>
            </div>
        </Section>
    )
}

export default DeliveringExcellence;
