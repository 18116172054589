import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper'
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'

const PrecisionTeam:FunctionComponent = ()=> {
    return (
        <Section className="bg-white px-6">
            <div id="precision-team" className="pb-5"></div>
            <div className="flex flex-col w-full h-full p-5  py-10 space-y-5">
                <Text className="text-center text-bytestrone-blue lg:text-left whitespace-nowrap" textType="headerL">Precision-Crafted Team</Text>
            
                <div>
                    <Text textType="contentXXl">
                        A precision-crafted team, working together can apply individual perspectives,
                        experience, and skills to solve complex problems, creating new solutions and ideas that
                        may be beyond the scope of any one individual.
                    </Text>
                </div>

                <div className="flex flex-wrap h-full items-center  justify-left md:pl-11 space-y-5">
   
                    <div className="lg:w-1/2">
                        <StaticImage alt="Precision-crafted team" placeholder="blurred" quality={100} 
                            src="../images/precision-crafted-team-ee.png" formats={["auto", "webp", "avif"]}/>
                    </div>

                    <div className="lg:w-1/2 md:pl-10">
                        <div className="flex flex-row h-30 py-2 items-center">
                            <div className="flex-none h-20 w-3 bg-bytestrone-blue">&nbsp;</div>
                            <span className="pl-10">Ready to deploy “Performing”<sup>1</sup> Team from Day 1 with zero
                                team-forming overheads</span>
                        </div>
                        <div className="flex flex-row h-30 py-2 items-center">
                            <div className="flex-none h-20 w-3 bg-bytestrone-green">&nbsp;</div>
                            <span className="pl-10">Quality first mindset infused into the DNA of pod, 
                                which will reflect in each pod-life moments</span>
                        </div>
                        <div className="flex flex-row h-30 py-2 items-center">
                            <div className="flex-none h-20 w-3 bg-bytestrone-red">&nbsp;</div>
                            <span className="pl-10">Autonomous, Cross-functional &amp; high-performing agile
                                team, responsible and capable to deliver end-to-end
                                engineering solution</span>
                        </div>
                        <div className="flex flex-row h-30 py-2 items-center">
                            <div className="flex-none h-20 w-3 bg-bytestrone-purple">&nbsp;</div>
                            <span className="pl-10">Co-located, pair programming model assuring rapid,
                                consistent &amp; high-quality deliverables by applying deep
                                engineering practices</span>
                        </div>
                        <div className="flex flex-row h-30 py-2 items-center">
                            <div className="flex-none h-20 w-3 bg-bytestrone-turquoise">&nbsp;</div>
                            <span className="pl-10">&quot;Expert Level&quot; practitioners on multiple technology
                                frameworks, tools and processes</span>
                        </div>
                        <div className="flex flex-row h-30 py-2 items-center">
                            <div className="flex-none h-20 w-3 bg-bytestrone-yellow">&nbsp;</div>
                            <span className="pl-10">Full Stack Engineers with rich hands-on experience in
                                digital engineering</span>
                        </div>                                                                                                                        
                    </div>
                </div>


                <div>
                    <Text textType="content">
                        1. Agile teams goes through, forming, norming, storming phase to reach performing maturity which is a time consuming activity.
                    </Text>
                </div>
            </div>
        </Section>
    )
}


export default PrecisionTeam;
