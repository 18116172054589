import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper'
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text'

const PragmaticSolution:FunctionComponent = ()=> {
    return (
        <Section className="bg-bytestrone-blue px-6">
            <div id="pragmatic-solution" className="flex flex-col w-full h-full p-5 md:py-10 py-20 space-y-5">
                <Text className="text-center text-white lg:text-right whitespace-nowrap" textType="headerL">Pragmatic One-Stop Solution</Text>
            
                <div className="flex flex-wrap h-full items-center  justify-center md:pl-11 space-y-5">
                    
                    <div className="lg:w-1/2">
                        <div className="lg:px-8 lg:w-3/4">
                            <Text textType="contentXXl" className="text-white">
                                Bytestrone provides a <strong>pragmatic</strong> solution to complex Software Engineering by bringing projects to 
                                <a href="#precision-team"><strong> precision-crafted teams </strong></a>
                                that are built on 
                                <a href="#unique-characteristics"><strong> character</strong></a>
                                , excels in 
                                <a href="#delivering-excellence"><strong> Engineering </strong></a>
                                 and embodies 
                                <a href="#agility-beyond"><strong> true agility</strong></a>.
                            </Text>
                        </div>
                    </div>
    
                    <div className="lg:w-1/2 xl:p-10">
                        <StaticImage className="md:w-3/4" style={{maxHeight:"650px"}}  aspectRatio={653 / 708}  alt="Pragmatic One-Stop Solution" placeholder="blurred" quality={100} 
                            src="../images/pragmatic-one-stop-solution-ee@2x.png" formats={["auto", "webp", "avif"]}/>
                    </div>

                    <div className="w-full flex flex-wrap flex-col md:flex-row lg:justify-around items-center space-y-1 lg:space-y-0 text-white">
                        <div className="bg-bytestrone-green rounded-full whitespace-nowrap text-center  p-2 w-64  text-base">PREMIUM QUALITY</div>
                        <div className="bg-bytestrone-green rounded-full whitespace-nowrap text-center  p-2 w-64 text-base">FASTER TIME TO MARKET</div>
                        <div className="bg-bytestrone-green rounded-full whitespace-nowrap text-center  p-2 w-64 text-base">OPTIMAL COST OF QUALITY</div>
                    </div>
                </div>
            </div>
        </Section>
    )
}
export default PragmaticSolution;
